import { useDispatch, useSelector } from 'react-redux';
import { login, register, logout } from 'src/redux/slices/auth';

// ----------------------------------------------------------------------

export default function useAuth() {
  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.auth,
  );

  return {
    method: 'jwt',
    user,
    isLoading,
    isAuthenticated,

    login: ({ email, password }) =>
      dispatch(
        login({
          email,
          password,
        }),
      ),

    register: ({ email, password, firstName, lastName }) =>
      dispatch(
        register({
          email,
          password,
          firstName,
          lastName,
        }),
      ),

    logout: () => dispatch(logout()),

    resetPassword: () => {},

    updateProfile: () => {},
  };
}
