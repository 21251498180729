const permissionKeys = {
  general: {
    dashboard: 'Dashbaord',
    alumniStory: 'Alumni Story',
    walkInEnquiry: 'Walk In Enquiry',
    onlineEnquiry: 'Online Enquiry',
    registrationEnquiry: 'Registration Enquiry',
    corporateEnquiry: 'Corporate Enquiry',
  },
  program: {
    root: 'Program',
    syllabus: 'Program Syllabus',
  },
  videoLearning: {
    root: 'Video Learning',
    access: 'Video Learning Access',
    practiceSession: 'Video Learning Practice Session',
    studentQuestion: 'Video Learning Student Question',
  },
  livestream: {
    root: 'Livestream',
    access: 'Livestream Access',
    schedule: 'Livestream Schedule',
  },
  cost: {
    root: 'Program Cost',
    categories: 'Program Cost Categories',
  },
  exam: {
    root: 'Exam',
    schedulesTranscript: 'Exam Schedules & Transcript',
  },
  evaluation: {
    root: 'Evaluation',
  },
  digitalSignature: {
    root: 'Digital Signature Document',
  },
  scholarship: {
    root: 'Scholarship',
  },
  paylater: { root: 'Paylater' },
  academicSchedule: { root: 'Academic Schedule' },
  communityPartner: { root: 'Community Partner' },
  finance: {
    purchaseOrder: 'Purchase Order',
    eFaktur: 'EFaktur',
    transactionInstallment: 'Transaction Installment',
  },
  promotion: {
    voucherCode: 'Vouchers Code',
    promo: 'Promo',
  },
  report: {
    salesReport: 'Sales Report',
    programSalesReport: 'Program Sales Report',
    installmentReport: 'Installment Report',
    installmentARReport: 'Installment AR Report',
    newInstallmentReport: 'New Installment Report',
    suspendedInstallmentReport: 'Suspended Installment Report',
  },
  user: {
    root: 'User',
    role: 'User Role',
  },
  student: {
    root: 'Student',
    report: 'Student Report',
    announcement: 'Student Announcement',
    birthday: 'Student Birthday',
    support: 'Student Support',
    attendees: 'Student Attendees',
    recommendation: 'Student Recommendation',
  },
  lecturer: {
    root: 'Lecturer',
  },
  operational: {
    root: 'Operational',
  },
  community: {
    pacMember: 'Community PAC Member',
    tribe: 'Community Tribe',
    activity: 'Community Activity',
    jobPosting: 'Community Job Posting',
    alumni: 'Community Alumni',
    company: 'Community Company',
    alumniContact: 'Community Alumni Contact',
  },
  elearningHub: {
    youtubePlaylists: 'Youtube Playlists',
    youtubeVideos: 'Youtube Videos',
  },
  notification: {
    root: 'Notification',
  },
  grade: {
    root: 'Grade List',
    batch: 'Grade Batch List',
  },
  support: {
    root: 'Support Ticket',
  },
};

export default permissionKeys;
