import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '.ck.ck-content ul, .ck.ck-content ul li ': {
        listStyleType: 'inherit',
      },
      '.ck.ck-content ul': {
        paddingLeft: '40px',
      },
      '.ck.ck-content ol, .ck.ck-content ol li': {
        listStyleType: 'decimal',
      },
      '.ck.ck-content ol': {
        paddingLeft: '40px',
      },
      '.ck.ck-balloon-panel': {
        zIndex: '9999!important',
      },
    },
  }),
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
