import { Icon } from '@iconify/react';
import Scrollbars from 'src/components/Scrollbars';
import PopoverMenu from 'src/components/PopoverMenu';
import bellFill from '@iconify-icons/eva/bell-fill';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState, useEffect } from 'react';
import doneAllFill from '@iconify-icons/eva/done-all-fill';
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography,
} from '@material-ui/core';
import { MIconButton } from 'src/theme';
import { appConfig } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
} from 'react-redux-firebase';
import NotificationItem from './NotificationItem';

function Notifications() {
  const firestore = useFirestore();
  const { user } = useAuth();
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [totalUnread, setTotalUnread] = useState(0);
  const dispatch = useDispatch();

  const environment = process?.env?.REACT_APP_ENVIRONMENT;

  useFirestoreConnect([
    {
      collection: 'notification',
      doc: appConfig.environment,
      subcollections: [
        {
          collection: (user?.id || 0).toString(),
          orderBy: ['createdAt', 'desc'],
          limit: 20,
        },
      ],
      storeAs: 'notification',
    },
  ]);

  const handleMarkAllAsRead = () => {
    // dispatch(markAllAsRead());
  };

  const updateNotification = (id) =>
    firestore
      .collection('notification')
      .doc(environment)
      .collection(user.id.toString())
      .doc(id.toString())
      .update({
        isRead: true,
        updatedAt: new Date(),
      });

  const notifData = useSelector(
    (state) => state.firestore.ordered.notification,
  );

  useEffect(() => {
    if (notifData) {
      const total = notifData.filter((item) => !item.isRead).length;
      setTotalUnread(total);
    }
  }, [notifData]);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        color={isOpen ? 'primary' : 'default'}
      >
        <Badge badgeContent={totalUnread} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <PopoverMenu
        width={360}
        open={isOpen}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnread} unread messages
            </Typography>
          </Box>

          {totalUnread > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Box sx={{ height: { xs: 340 } }}>
          <Scrollbars>
            <List disablePadding>
              {isLoaded(notifData) &&
                notifData.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    updateNotification={updateNotification}
                  />
                ))}
            </List>
          </Scrollbars>
        </Box>

        <Divider />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box>
      </PopoverMenu>
    </>
  );
}

export default Notifications;
