import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { formatDistanceToNow } from 'date-fns';
import clockFill from '@iconify-icons/eva/clock-fill';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Avatar,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5, 2.5),
    '&:not(:last-child)': {
      marginBottom: 1,
    },
  },
  isUnread: {
    backgroundColor: theme.palette.action.selected,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
}));

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
      >
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  return {
    avatar: (
      <img
        alt={notification.title}
        src="/static/icons/ic_notification_chat.svg"
      />
    ),
    title,
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  className: PropTypes.string,
  updateNotification: PropTypes.func,
};

function NotificationItem({ notification, className, updateNotification }) {
  const classes = useStyles();
  const { avatar } = renderContent(notification);
  const { title } = renderContent(notification);

  return (
    <ListItem
      button
      to={notification.redirectUrl}
      disableGutters
      key={notification.id}
      component={RouterLink}
      className={clsx(
        classes.root,
        {
          [classes.isUnread]: !notification.isRead,
        },
        className,
      )}
      onClick={() => updateNotification(notification.id)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {formatDistanceToNow(
              new Date(notification.createdAt.seconds * 1000),
            )}
          </Typography>
        }
      />
    </ListItem>
  );
}

export default NotificationItem;
