/* eslint-disable react/jsx-props-no-spreading */
import NProgress from 'nprogress';
import LoadingScreen from 'src/components/LoadingScreen';
import GuestProtect from 'src/components/Auth/GuestProtect';
import AuthProtect from 'src/components/Auth/AuthProtect';
import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Suspense, Fragment, lazy, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useAuth from 'src/hooks/useAuth';
import { PATH_ADMIN, PATH_PAGE } from './paths';
import AdminRoutes from './AdminRoutes';

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
      },
    },
  },
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false,
  });

  useEffect(() => {
    NProgress.done();
    return () => {
      NProgress.start();
    };
  }, []);

  return <Route {...props} />;
}

export function RenderRoutes({ routes = [] }) {
  const { user } = useAuth();
  const { access = [] } = user || {};

  const { showAll, availableMenus } = useMemo(() => {
    let permissionList = [];
    let isSuperAdmin = false;

    access.forEach(({ name, permissions = [] }) => {
      if (name === 'Super Admin') {
        isSuperAdmin = true;
      }

      permissionList = [
        ...permissionList,
        ...permissions.map((p) => p?.form?.name),
      ];
    });

    return { availableMenus: permissionList, showAll: isSuperAdmin };
  }, [access]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          const renderComponent = (props) => {
            if (route.routes) {
              return <RenderRoutes routes={route.routes} />;
            }
            if (
              !showAll &&
              user?.id &&
              route.key &&
              !availableMenus.includes(route.key)
            ) {
              return <Redirect to="/not-found" />;
            }
            return <Component {...props} />;
          };

          return (
            <RouteProgress
              key={i.toString()}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>{renderComponent(props)}</Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = [
  {
    exact: true,
    guard: AuthProtect,
    path: '/',
    component: () => <Redirect to={PATH_ADMIN.generalSection.dashboard} />,
  },

  // Admin Routes
  AdminRoutes,

  // Others Routes
  {
    exact: true,
    guard: GuestProtect,
    path: PATH_PAGE.auth.login,
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  // {
  //   exact: true,
  //   guard: GuestProtect,
  //   path: PATH_PAGE.auth.register,
  //   component: lazy(() => import('src/views/auth/RegisterView')),
  // },
  {
    exact: true,
    path: PATH_PAGE.auth.resetPassword,
    component: lazy(() => import('src/views/auth/ResetPasswordView')),
  },
  {
    exact: true,
    path: PATH_PAGE.auth.verify,
    component: lazy(() => import('src/views/auth/VerifyCodeView')),
  },
  {
    exact: true,
    path: PATH_PAGE.notFound,
    component: lazy(() => import('src/views/errors/Page404View')),
  },
  {
    exact: true,
    path: PATH_PAGE.error,
    component: lazy(() => import('src/views/errors/Page500View')),
  },
  {
    exact: true,
    path: PATH_PAGE.comingSoon,
    component: lazy(() => import('src/views/pages/ComingSoonView')),
  },
  {
    exact: true,
    path: PATH_PAGE.maintenance,
    component: lazy(() => import('src/views/pages/MaintenanceView')),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: PATH_PAGE.watchPage,
    component: lazy(() => import('src/views/pages/LivestreamWatchPage')),
  },
  {
    exact: true,
    path: PATH_PAGE.auth.root,
    component: () => <Redirect to={PATH_PAGE.auth.login} />,
  },
  {
    exact: true,
    guard: AuthProtect,
    path: PATH_PAGE.watchVideoLearning,
    component: lazy(() => import('src/views/pages/OnlineLearningWatchPage')),
  },

  {
    component: () => <Redirect to="/not-found" />,
  },
];

export default routes;
