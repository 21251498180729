/* eslint-disable import/prefer-default-export */
import crypto from 'crypto';

export const encrypt = (key, data) => {
  const cipher = crypto.createCipher('aes192', key);
  let crypted = cipher.update(data, 'utf-8', 'hex');
  crypted += cipher.final('hex');

  return crypted;
};
